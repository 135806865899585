.privacy {
  margin-top: 60px;
  padding: 10px 30px;
  margin-bottom: 50px;
}
.privacy-header {
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-position-y: 40%;
  color: white;
  text-align: left;
  margin-bottom: 20px;
  height: 300px;
  background-image: url("../../images/useful_images_svg/_1f738d6d-0235-4de8-84c1-5dac8cd3e610.jpeg");
}
.privacy-content {
    text-align: justify;
}
@media screen and (max-width: 950px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
}
@media screen and (max-width: 300px) {
}
